<template>
  <div class="add-rank-dialog">
    <page-dialog @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="新增部门"
      :isShowFooter="true" @handleSaveClick="handleSaveClick">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="80px" class="demo-ruleForm"
        label-position="left">
        <el-form-item label="部门名称:" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
      </el-form>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import store from '@/store'
import { COMPANY_ID } from '@/common/constant'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const ruleForm = reactive({
      name: ''
    })
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }

    const handleSaveClick = () => {
      // console.log(1);
      store.dispatch('staff/addDepartmentAction', [COMPANY_ID, ruleForm.name])
    }

    return {
      changeDialogVisible,
      ruleForm,
      handleSaveClick,
      COMPANY_ID
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  &:deep(.el-select) {
    width: 100%;
  }
}
</style>




