<template>
  <div class="dialog-form">
    <el-dialog v-if="isShow" v-model="isShow" title="添加员工" width="500px">
      <el-scrollbar height="400px">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="addStaffRules" label-width="80px" class="demo-ruleForm"
          :size="formSize">
          <el-form-item label="姓名" prop="userRealname">
            <el-input placeholder="请输入真实姓名" v-model="ruleForm.userRealname"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="userMobile">
            <el-input placeholder="请输入手机号" v-model="ruleForm.userMobile"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="name">
            <el-input readonly placeholder="密码将由系统自动生成,并短信通知员工" v-model="ruleForm.userPassword"></el-input>
          </el-form-item>
          <el-form-item label="头像" prop="userPhoto">
            <upload-img :limit="1" @handleUploadRemove="handleUploadRemove" ref="imgUploadRef"
              @handleUploadSuccess="handleImgUploadSuccess"></upload-img>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-select v-model="ruleForm.gender" class="m-2" placeholder="选择性别" size="large">
              <el-option v-for="item in genderList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择门店" prop="shopId">
            <div class="shop">
              <el-select @change="handleShopChange" v-model="ruleForm.shopId" class="m-2" placeholder="选择门店"
                size="large">
                <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
                </el-option>
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="部门" prop="dpId">
            <el-select v-model="ruleForm.dpId" class="m-2" placeholder="所属部门" size="large">
              <el-option v-for="item in departmentList" :key="item.dpId" :label="item.dpName" :value="item.dpId">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="所属职位" prop="jobLevel">
            <el-select @change="handleJobLevelChange" v-model="ruleForm.jobLevel" class="m-2" placeholder="选择职位"
              size="large">
              <el-option v-for="item in staffPositionList" :key="item.lvId" :label="item.levelName" :value="item.lvId">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="所属职级" prop="subJobLevel">
            <el-select :disabled="typeof ruleForm.jobLevel == 'number' ? false : true" v-model="ruleForm.subJobLevel"
              class="m-2" :placeholder="typeof ruleForm.jobLevel == 'number' ? '选择职位' : '先选择职位'" size="large">
              <el-option v-for="item in staffChildRankList" :key="item.lvId" :label="item.levelName" :value="item.lvId">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="入职时间" prop="joinTime">
            <el-date-picker v-model="ruleForm.joinTime" type="date" placeholder="请选择日期"></el-date-picker>
          </el-form-item>

          <el-form-item label="介绍" prop="name">
            <el-input :rows="4" type="textarea" maxlength="100" placeholder="请输入个性签名" show-word-limit
              v-model="ruleForm.userPersonInfo"></el-input>
          </el-form-item>
        </el-form>
      </el-scrollbar>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleSaveClick(ruleFormRef)">保存</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import { useState } from '@/hooks'
import { rankProps, genderList } from './config'
import { addStaffRules } from '../../../../config'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UploadImg

  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const isShow = ref(props.dialogVisible)
    watch(() => props.dialogVisible, (newValue) => {
      isShow.value = newValue
    }, {
      deep: true
    })
    watch(isShow, (newValue) => {
      emit('changeDialogVisible', newValue)
    })

    const ruleFormRef = ref()

    const initPage = () => {
      store.dispatch('staff/getStaffPositionAction', COMPANY_ID)
      store.dispatch('staff/getStaffDeparentAction', {
        companyId: COMPANY_ID
      })
      store.dispatch('reserve/getShopListAction', COMPANY_ID)
    }
    initPage()

    const handleUploadRemove = (res) => {
      ruleForm.userPhoto = ''
    }
    const ruleForm = reactive({
      companyId: COMPANY_ID,
      userMobile: '',
      userPassword: '',
      userRealname: '',
      joinTime: '',
      userPersonInfo: '',
      userPhoto: '',
      gender: '',
      dpId: '',
      jobLevel: '',
      subJobLevel: '',
      shopId: ''
    })
    const ruleFormRank = ref('')


    const imgUploadRef = ref('')
    const handleImgUploadSuccess = (res) => {
      ruleForm.userPhoto = res.response.data.fileName

    }

    const handleRankChange = (value) => {
      ruleForm.jobLevel = value[0]
      if (value.length == 2) {
        ruleForm.subJobLevel = value[1]
      }
    }

    const emptyForm = () => {
      ruleFormRank.value = ''
      imgUploadRef.value.emptyFileList()
      for (let key in ruleForm) {
        if (key != 'companyId') {
          ruleForm[key] = ''
        }
      }
    }

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          if (ruleForm.userPhoto.length == 0) {
            ElMessage({
              message: '请上传员工头像',
              type: 'warning',
            })
            return
          }
          ruleForm.userPassword = '123456!'
          store.dispatch('staff/addStaffAction', { ...ruleForm })
          emptyForm()

          // console.log('submit!')
        } else {
          ElMessage({
            message: '带*为必填项,请正确填写数据',
            type: 'warning',
          })
          // console.log('error submit!', fields)
        }
      })

    }

    const staffPositionList = computed(() => store.state.staff.staffPositionList)
    const departmentList = computed(() => store.state.staff.departmentList)
    const staffChildRankList = computed(() => store.state.staff.staffChildRankList)
    const shopList = computed(() => store.state.reserve.shopList)

    const handleJobLevelChange = (value) => {
      store.dispatch('staff/getStaffChildRankAction', value)
    }

    return {
      handleUploadRemove,
      shopList,
      handleJobLevelChange,
      staffPositionList,
      isShow,
      ruleForm,
      imgUploadRef,
      COMPANY_ID,
      handleImgUploadSuccess,
      ruleFormRank,
      handleRankChange,
      handleSaveClick,
      rankProps,
      departmentList,
      genderList,
      staffChildRankList,
      addStaffRules,
      ruleFormRef
    }

  }
})
</script>

<style scoped lang="less">
.dialog-form {
  &:deep(.el-dialog__header) {
    border-bottom: 1px solid #ccc;
  }

  &:deep(.el-upload) {
    width: 100px;
    height: 100px;


  }

  &:deep(.el-upload--picture-card i) {
    margin-top: 34px;
  }

  &:deep(.el-upload-list) {
    .el-upload-list__item {
      width: 100px;
      height: 100px;
    }
  }

  .shop {
    &:deep(.el-select) {
      width: 100%;
    }
  }

  &:deep(.el-scrollbar) {
    padding-right: 20px;
  }

  // &:deep(.el-upload) {
  //   border: 1px dashed #d9d9d9;
  //   border-radius: 6px;
  //   cursor: pointer;
  //   width: 60px;
  //   height: 60px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   .el-icon {
  //   }
  // }
  &:deep(.el-form-item) {

    .el-select,
    .el-cascader {
      width: 100% !important;
      max-width: 100% !important;
    }

    .el-date-editor {
      width: 100% !important;
    }
  }
}
</style>
