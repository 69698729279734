<template>
  <div class="staff-management">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="staff-management-content">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="员工管理" name="a">
          <top-content :dpId="currentDpId"></top-content>
          <div class="main-content">
            <rank-category class="category" @handleTreeItemChange="handleTreeItemChange"
              @handleTreeNodeClick="handleTreeNodeClick">
            </rank-category>
            <staff-list :dpId="currentDpId" class="staff-list"></staff-list>
          </div>
        </el-tab-pane>
        <el-tab-pane label="岗位管理" name="b">
          <rankManagement></rankManagement>
        </el-tab-pane>

        <el-tab-pane label="角色管理" name="c">
          <roleManagementVue></roleManagementVue>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import TopContent from './cpns/top-content/top-content'
import StaffList from './cpns/staff-list/staff-list'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import RankCategory from './cpns/rank-category/rank-category'
import rankManagement from '../rank-management/rank-management.vue'
import roleManagementVue from '../role-management/role-management.vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {
    TopContent,
    StaffList,
    Breadcrumb,
    RankCategory,
    rankManagement,
    roleManagementVue
  },
  name: 'staffManagement',
  setup() {
    const route = useRoute()
    const currentDep = ref()
    const currentDpId = ref()
    const handleTreeItemChange = item => {
      currentDep.value = item
    }

    const handleTreeNodeClick = (dpId) => {
      currentDpId.value = dpId
    }

    const activeName = ref('a')
    const handleClick = (tab) => {
      let name = tab.props.name
      if (name == 'a') {
        breadcrumbList.value[1].name = '员工列表'
      } else if (name == 'b') {
        breadcrumbList.value[1].name = '岗位管理'
      } else if (name == 'c') {
        breadcrumbList.value[1].name = '角色管理'
      }

    }

    const breadcrumbList = ref([
      {
        name: '员工管理',
        path: ''
      },
      {
        name: '员工列表',
        path: ''
      }
    ])

    watch(() => route.path, (n) => {
      if (n == '/main/staff/staffManagement') {
        activeName.value = 'a'
      }
    })

    return {
      handleClick,
      activeName,
      handleTreeItemChange,
      handleTreeNodeClick,
      breadcrumbList,
      currentDep,
      currentDpId
    }

  }
})
</script>

<style scoped lang="less">
.staff-management {
  min-width: 1080px;

  .staff-management-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;


    .main-content {
      display: flex;
      justify-content: space-between;

      .category {
        margin-top: 20px;

      }

      .staff-list {
       width:82%
      }
    }
  }

}
</style>




