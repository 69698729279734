export const staffState = [
  {
    label: '全部',
    value: ''
  },
  {
    label: '使用中',
    value: 1
  },
  {
    label: '已停用',
    value: 2
  }
]

export const rules = {
  name: [{ required: true, message: '输入部门名称', trigger: 'blur' }]
}


