<template>
  <div class="rank-category">
    <page-tree @handleNodeClick="handleTreeNodeClick" :treeProps="{ label: 'dpName', children: children }"
      :agencyListInfo="staffDepartmentList">
      <template #count="row">
        <div class="count">（{{row.row.data.enabledUserCount}}）</div>
      </template>
      <template #edit="row">
        <div v-if="row.row.data.category" class="edit" @click.stop="handleRowClick(row)">编辑</div>
      </template>
      <template #remove="row">
        <div v-if="row.row.data.category" class="remove" @click.stop="handleRemoveClick(row)">删除</div>
      </template>
    </page-tree>
    <updateDepDialog :depInfo="currrentEditDepInfo" v-if="updateDialogVisible" :dialogVisible='updateDialogVisible'
      @changeDialogVisible='changeupdateDialogVisible'></updateDepDialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, onUnmounted } from 'vue'
import PageTree from '@/components/page-tree/page-tree.vue'
import { useState } from '@/hooks'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import updateDepDialog from '../../base-ui/update-dep-dialog/update-dep-dialog.vue'
import { removeDepInfo } from '@/service/main/staff'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {

  },
  components: {
    PageTree,
    updateDepDialog
  },
  emits: ['handleTreeNodeClick', 'handleTreeItemChange'],
  setup(props, { emit }) {
    const store = useStore()
    const rankListState = useState('staff', ['staffDepartmentList'])
    // console.log(rankListState);

    const initPage = () => {
      store.dispatch('staff/getDepartmentListAction', COMPANY_ID)
    }
    initPage()


    const handleTreeNodeClick = (item) => {
      emit('handleTreeNodeClick', item.data.dpId)
      emit('handleTreeItemChange', item)
    }

    emitter.on('changeStaffDepartmentFirstDpId', id => {
      emit('handleTreeNodeClick', id)
    })

    emitter.on('refreshStaffDepartmentList', () => {
      initPage()
    })

    onUnmounted(() => {
      emitter.off('refreshStaffDepartmentList')
      emitter.off('changeStaffDepartmentFirstDpId')
    })

    const currrentEditDepInfo = ref()
    const handleRowClick = item => {
      let data = item.row.data
      currrentEditDepInfo.value = data
      updateDialogVisible.value = true
      // console.log(item);
    }

    const updateDialogVisible = ref(false)
    const changeupdateDialogVisible = flag => {
      updateDialogVisible.value = flag
    }

    const handleRemoveClick = async (row) => {
      let data = row.row.data
      const res = await removeDepInfo(data.dpId)
      if (res.code == 0) {
        ElMessage({
          message: '操作成功!',
          type: 'success',
        })
        initPage()
      } else {
        ElMessage({
          message: res.msg,
          type: 'warning',
        })
      }
    }

    return {
      handleRemoveClick,
      updateDialogVisible,
      changeupdateDialogVisible,
      handleRowClick,
      currrentEditDepInfo,
      ...rankListState,
      COMPANY_ID,
      handleTreeNodeClick
    }

  }
})
</script>

<style scoped lang="less">
.rank-category {
  min-width: 260px;
  max-width: 320px;
  height: 100%;
  background-color: white;

  &:deep(.el-tree-node__content) {

    .remove,
    .edit {
      visibility: hidden;
      color: rgb(64, 158, 255);
      font-size: 12px;
    }
    .count{
      color: rgb(64, 158, 255);
      font-size: 12px;
    }

    .edit {
      margin-left: 14px;
    }

    .remove {
      margin-left: 10px;
    }
  }

  &:deep(.el-tree-node__content):hover {

    .remove,
    .edit {
      visibility: visible !important;
    }
  }

  &:deep(.el-tree) {
    &>div:nth-child(1) {
      // background-color: rgb(255, 234, 234) !important;
      // color: rgba(255, 141, 26, 1);
    }
  }

  &:deep(.is-current) {
    background-color: rgb(255, 234, 234) !important;
    color: rgba(255, 141, 26, 1);
  }
}
</style>




