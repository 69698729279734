export const breadcrumbList = [
  {
    name: '员工管理',
    path: ''
  },
  {
    name: '员工列表',
    path: ''
  }
]

export const addStaffRules = {
  userRealname: [
    { required: true, message: '请输入员工姓名', trigger: 'blur' }
  ],
  userMobile: [
    { required: true, message: '请输入员工手机号', trigger: 'blur' }
  ],
  gender: [{ required: true, message: '请选择员工性别', trigger: 'blur' }],
  shopId: [{ required: true, message: '请选择员工所在门店', trigger: 'blur' }],
  dpId: [{ required: true, message: '请选择员工所在部门', trigger: 'blur' }],
  jobLevel: [{ required: true, message: '请选择员工的职位', trigger: 'blur' }],
  subJobLevel: [{ required: true, message: '请选择员工的职级', trigger: 'blur' }],
  joinTime:[{ required: true, message: '请选择员工入职时间', trigger: 'blur' }],
  userPhoto:[{ required: true, message: '请上传员工头像', trigger: 'blur' }]
}
