<template>
  <div class="add-staff-dialog">
    <page-dialog width="500px" @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible" title="添加员工"
      :isShowFooter="true" @handleSaveClick="handleSaveClick">
      <div class="content">
        <div class="left-item">
          <el-select clearable v-model="queryInfo.shopid" class="shop-info" placeholder="所属门店" size="small">
            <el-option v-for="item in belongShopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
            </el-option>
          </el-select>

          <div class="search">
            <el-input @change="handleSearchChange" clearable v-model="queryInfo.keyword" size="small"
              placeholder="搜索姓名手机号"></el-input>
            <el-button @click="handleSearchClick" size="small" plain class="search-btn" type="primary">搜索</el-button>
          </div>

          <div class="staff-list">
            <div class="tips">共{{ staffCount }}位员工</div>
            <el-scrollbar height="250px">
              <template :key="item" v-for="item in staffCopyList">
                <div :class="{ isActive: staffGroupValue.findIndex(ele => ele.userId == item.userId) != -1 }"
                  @click="handleStaffClick(item)" class="staff-item">
                  <div class="left">
                    <img :src="item.userPhoto" alt />
                    <div class="name">{{ item.userRealname }}</div>
                  </div>
                  <div class="right">
                  </div>
                </div>
              </template>
            </el-scrollbar>
          </div>
          <el-pagination v-model:currentPage="queryInfo.page" v-model:page-size="queryInfo.pageSize"
            :page-sizes="[10, 20, 30, 40]" :small="true" layout=" sizes, prev, pager, next" :total="staffCount"
            :pager-count="3" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
        <div class="right-item">
          <div class="tips">已选员工</div>
          <div class="opt-staff-list">
            <template :key="item" v-for="item in staffGroupValue">
              <div class="opt-staff">
                <div class="name">{{ item.userRealname }}</div>
                <div @click="handleRemoveClick(item)" class="remove">移除</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useState } from '@/hooks'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { nextTick } from 'process'
import { getStaffList } from '@/service/main/staff'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dpId: {
      type: Number,
      default: -1
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible'],
  setup(props, { emit }) {
    const store = useStore()
    const ruleForm = reactive({
      name: ''
    })
    const changeDialogVisible = (flag) => {
      initPage()
      emit('changeDialogVisible', flag)
    }

    const currentPage = reactive({
      page: 1,
      pageSize: 10
    })

    const handleSizeChange = (pageSize) => {
      currentPage.pageSize = pageSize
    }

    const handleCurrentChange = (page) => {
      currentPage.page = page
    }

    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const staffList = ref('')
    const staffCount = ref(0)

    const getStaffListFunc = async () => {
      const res = await getStaffList({ ...queryInfo })
      let list = res.data.allEmp
      let total = res.data.total
      staffList.value = list
      staffCount.value = total
    }


    const initPage = async () => {
      getStaffListFunc()
      store.dispatch('staff/getBelongShopAction', COMPANY_ID)
    }

    const belongShopList = computed(() => store.state.staff.belongShopList)
    const queryInfo = reactive({
      page: 1,
      pageszie: 10,
      status: 1,
      keyword: '',
      shopid: '',
      joblv: '',
      subjoblv: '',
      companyId: COMPANY_ID
    })

    const handleSearchClick = async () => {
      getStaffListFunc()
    }

    const handleSearchChange = (value) => {
      if (value.length == 0) {
        getStaffListFunc()
      }
    }

    watch(() => queryInfo.page, () => {
      getStaffListFunc()
    }, {
      deep: true
    })

    const handleStaffClick = item => {
      const index = staffGroupValue.value.findIndex(ele => ele.userId == item.userId)
      if (index != -1) {
        staffGroupValue.value.splice(index, 1)
      } else {
        staffGroupValue.value.push(item)
      }
    }

    const dpIdStaffList = computed(() => store.state.staff.dpIdStaffList)

    watch(() => props.dpId, (n) => {
      initPage()
    })

    const staffCopyList = ref([])
    const formState = useState('staff', [])
    const staffGroupValue = ref([])
    const currentOptStaffList = ref([])
    const handleCheckBoxChange = (value) => {
    }

    watch(staffList, (n) => {
      if (n != null) {
        staffCopyList.value = n
      }
    })

    const handleRemoveClick = (item) => {
      const index = staffGroupValue.value.findIndex(val => val.userId == item.userId)
      staffGroupValue.value.splice(index, 1)
    }

    const handleSaveClick = () => {
      store.dispatch('staff/saveStaffDeparentBindAcrion', {
        userList: [...staffGroupValue.value],
        dpId: props.dpId,
      })
      staffGroupValue.value = []
    }

    return {
      getStaffList,
      staffCount,
      belongShopList,
      handleSearchChange,
      queryInfo,
      handleSearchClick,
      changeDialogVisible,
      ruleForm,
      COMPANY_ID,
      ...formState,
      staffGroupValue,
      UPLOAD_BASE_URL,
      handleCheckBoxChange,
      handleRemoveClick,
      handleSaveClick,
      dpIdStaffList,
      currentOptStaffList,
      currentPage,
      handleSizeChange,
      handleCurrentChange,
      staffList,
      staffCopyList,
      handleStaffClick

    }

  }
})
</script>

<style scoped lang="less">
.add-staff-dialog {
  font-size: 12px;

  .isActive {
    border: 2px solid var(--el-color-primary);
  }

  &:deep(.el-checkbox-button.is-checked .el-checkbox-button__inner) {
    background-color: white;
    border: 2px solid var(--el-checkbox-button-checked-border-color);
    color: black;
    border-color: none;
  }



  &:deep(.el-dialog__body) {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .tips {
    margin: 12px 0;
  }

  &:deep(.el-checkbox-group) {
    label {
      display: block;
      padding-left: 2px;

      span {
        height: 50px;
        display: flex;
        align-items: center;
      }
    }

    .el-checkbox-button__inner {
      border-left: 1px solid #dcdfe6;
    }

    .el-checkbox-button {
      height: 50px;
    }
  }

  &:deep(.el-select) {
    width: 100%;
  }

  .content {
    display: flex;


    .left-item {
      flex: 1;

      .form {
        display: flex;
        justify-content: space-between;

        .el-select-1 {
          margin-right: 10px;
        }
      }

      .search {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .search-btn {
          margin-left: 10px;
        }
      }

      .staff-list {
        margin-top: 20px;

        .staff-item {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 50px;
          line-height: 50px;
          padding: 0 10px;
          border-radius: 4px;
          margin-bottom: 10px;

          .left {
            display: flex;
            align-items: center;

            img {
              width: 30px;
              height: 30px;
              border-radius: 50%;
              margin-right: 10px;
            }
          }
        }

        &:deep(.el-checkbox-group) {
          .el-checkbox-button {
            width: 100%;
            margin-bottom: 10px;

            span {
              width: 100%;
            }
          }
        }
      }
    }

    .right-item {
      margin-left: 10px;
      padding-left: 10px;
      flex: 1;
      border-left: 1px solid var(--borderColor);

      .tips {
        line-height: 30px;
        background-color: rgb(239, 240, 240);
        font-size: 12px;
        padding: 0 10px;
      }

      .opt-staff-list {
        .opt-staff {
          display: flex;
          justify-content: space-between;
          margin: 6px 0;
          height: 40px;
          line-height: 40px;
          border-bottom: 1px solid rgb(229, 229, 229);

          .remove {
            color: rgb(102, 177, 255);
            font-size: 13px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
