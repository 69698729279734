<template>
    <div class="top-content">
        <div class="left">
            <div>
                <el-button class="add-staff"
                    type="primary"
                    @click="handleAddStaffClick"
                    v-has="'staff:add'">
                    <el-icon>
                        <plus />
                    </el-icon>添加员工
                </el-button>
            </div>
            <div>
                <el-button class="add-staff"
                    @click="handleAddRoleClick"
                    type="primary"
                    v-has="'staff:add:department'">
                    <el-icon>
                        <plus />
                    </el-icon>新增部门
                </el-button>
            </div>
            <el-dropdown trigger="click">
                <el-button type="primary">
                    批量操作<el-icon class="el-icon--right">
                        <arrow-down />
                    </el-icon>
                </el-button>
                <template #dropdown>
                    <el-dropdown-menu>
                        <el-dropdown-item @click="handleBatchSetStateClick(1)">启用账号</el-dropdown-item>
                        <el-dropdown-item @click="handleBatchSetStateClick(2)">停用账号</el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </div>
        <div class="right">
            <el-select clearable
                v-model="ruleForm.shopId"
                class="shop-info"
                placeholder="所属门店"
                size="large">
                <el-option v-for="item in belongShopList"
                    :key="item.shopId"
                    :label="item.shopName"
                    :value="item.shopId">
                </el-option>
            </el-select>

            <el-select clearable
                @change="positionChange"
                v-model="ruleForm.jobLevel"
                class="m-2"
                placeholder="所属职位"
                size="large">
                <el-option v-for="item in staffAllRankList"
                    :key="item.lvId"
                    :label="item.levelName"
                    :value="item.lvId">
                </el-option>
            </el-select>

            <el-select clearable
                v-model="ruleForm.subJobLevel"
                class="m-2"
                placeholder="所属职级"
                size="large">
                <el-option v-for="item in staffChildRankList"
                    :key="item.lvId"
                    :label="item.levelName"
                    :value="item.lvId">
                </el-option>
            </el-select>

            <el-select clearable
                v-model="ruleForm.status"
                class="m-2"
                placeholder="账号状态"
                size="large">
                <el-option v-for="item in staffState"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
            <div>
                <el-input clearable
                    @change="handleSearchChange"
                    class="search"
                    prefix-icon='search'
                    v-model="ruleForm.keyword"
                    placeholder="账号或姓名关键字" />
            </div>
            <div class="search-btn">
                <el-button @click="handleSearchClick"
                    type="primary">搜索</el-button>
            </div>

        </div>
    </div>
    <add-staff-form @changeDialogVisible='changeDialogVisible'
        :dialogVisible="addStaffDialogVisible"></add-staff-form>
    <add-staff-dialog :dpId="dpId"
        :dialogVisible="addStaffDepDialogVisible"
        @changeDialogVisible="changeAddStaffDepDialogVisible"></add-staff-dialog>

    <addDeparmentDialog v-if="addRoleDialogVisible"
        :dialogVisible='addRoleDialogVisible'
        @changeDialogVisible='changeAddRoleDialogVisible'>
    </addDeparmentDialog>

</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch } from 'vue'
import AddStaffForm from './cpns/add-staff-form/add-staff-form'
import { useStore } from 'vuex'
import { staffState } from './config'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import AddStaffDialog from '../add-staff-dialog/add-staff-dialog'
import addDeparmentDialog from './cpns/add-deparment/add-deparment'
export default defineComponent({
  props: {
    dpId: {
      type: String,
      default: '-1'
    }
  },
  components: {
    AddStaffForm,
    AddStaffDialog,
    addDeparmentDialog
  },
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()

    const addStaffDialogVisible = ref(false)
    const handleAddStaffClick = () => {
      router.push('/main/staff/addStaffPage')
    }
    const changeDialogVisible = (flag) => {
      addStaffDialogVisible.value = flag
    }
    const batchVisible = ref(false)

    const positionChange = (pid) => {
      ruleForm.subJobLevel = ''
      store.dispatch('staff/getStaffChildRankAction', pid)
    }

    const belongShopList = computed(() => store.state.staff.belongShopList)
    const staffAllRankList = computed(() => store.state.staff.staffAllRankList)
    const staffChildRankList = computed(() => store.state.staff.staffChildRankList)

    const initPage = () => {
      store.dispatch('staff/getBelongShopAction', COMPANY_ID)
      store.dispatch('staff/getStaffAllRankListAction', COMPANY_ID)
    }
    initPage()

    const ruleForm = reactive({
      status: '',
      keyword: '',
      shopId: '',
      jobLevel: '',
      subJobLevel: ''
    })

    const handleSearchClick = () => {
      emitter.emit('changeStaffManagementTopcontentSearch')
    }

    const handleSearchChange = (value) => {
      if (value.length == 0) {
        emitter.emit('changeStaffManagementTopcontentEmpty')
      }
    }

    watch(ruleForm, (n) => {
      emitter.emit('changeStaffManagementTopcontentRuleForm', { ...n })

    }, {
      deep: true
    })

    const handleBatchSetStateClick = (value) => {
      emitter.emit('staffManagementTopContentBatchSetState', value)
    }

    const addStaffDepDialogVisible = ref(false)
    const changeAddStaffDepDialogVisible = flag => {
      addStaffDepDialogVisible.value = flag
    }

    const handleAddStaffDepClick = () => {

      if (props.dpId == '-1') {
        ElMessage({
          message: '请点击左侧部门列表，选择要操作的部门！',
          type: 'warning',
        })
        return
      }
      addStaffDepDialogVisible.value = true
    }

    const addRoleDialogVisible = ref(false)

    const changeAddRoleDialogVisible = flag => {
      addRoleDialogVisible.value = flag
    }
    const handleAddRoleClick = () => {
      addRoleDialogVisible.value = true
    }

    const handleRankClick = () => {
      router.push('/main/staff/rankManagement')
    }

    const handleRoleClick = () => {
      router.push('/main/staff/roleManagement')
    }

    return {
      handleRoleClick,
      handleRankClick,
      handleAddRoleClick,
      changeAddRoleDialogVisible,
      addRoleDialogVisible,
      handleAddStaffDepClick,
      changeAddStaffDepDialogVisible,
      addStaffDepDialogVisible,
      handleBatchSetStateClick,
      batchVisible,
      handleSearchClick,
      staffAllRankList,
      ruleForm,
      belongShopList,
      staffState,
      addStaffDialogVisible,
      handleAddStaffClick,
      changeDialogVisible,
      positionChange,
      staffChildRankList,
      handleSearchChange
    }

  }
})
</script>

<style scoped lang="less">
@media screen and (max-width: 1258px) {
  .top-content {
    justify-content: space-between;
  }
}
@media screen and (max-width: 1840px) {
  .top-content {
    justify-content: space-between;
    .right {
      &:deep(.el-select) {
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (min-width: 1259px) {
  .top-content {
    justify-content: space-between;
  }
}

.top-content {
  display: flex;
  background-color: white;
  flex-wrap: wrap;

  &:deep(.dep-btn) {
    margin-right: 10px;
  }

  .left {
    display: flex;
  }
  .left {
    flex: 1;
  }

  .right {
    flex: 2;
  }

  .right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    &:deep(.el-button) {
      margin-bottom: 0px;
      margin-right: 10px;
    }
  }

  .shop-info {
    margin-left: 0px;
  }

  .bulk-operations-box {
    margin-right: 10px;
  }

  .add-staff {
    margin-right: 10px;
    margin-bottom: 10px;
  }

  &:deep(.el-select) {
    max-width: 120px !important;
    min-width: 90px;
    margin-right: 10px;
  }

  &:deep(.el-cascader) {
    max-width: 120px !important;
    margin-right: 10px;
    min-width: 90px;
  }

  .search {
    max-width: 200px;
    margin-right: 10px;
  }
}
</style>




