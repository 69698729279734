<template>
  <div class="staff-list">
    <page-content @selectionChange="selectionChange" :dataList="staffList" :dataCount="staffCount"
      :contentTableConfig="contentTableConfig" :isButtonStyle="true" @tableCellClick="handleTableCellClick">
      <template #handler="scope">
        <div class="handle-btns">
          <el-button plain size="mini">详情</el-button>
          <el-button plain size="mini" v-has="'staff:edit'" @click.stop="handleEditClick(scope.row)">编辑</el-button>
          <div class="handle-wrap" style="marginLeft:10px;">
            <template v-if="scope.row.status == 1">
              <el-switch active-value="1" inactive-value="0" v-model="active" />
            </template>
            <template v-else>
              <el-switch active-value="1" inactive-value="0" v-model="inactive" />
            </template>
            <div @click.stop="setStaffState(scope.row)" class="el-switch-mask"></div>
          </div>
        </div>
      </template>
      <template #userRealname="scope">
        <div class="dpName">
          <div class="avatar">
            <img v-if="scope.row.userPhoto != null" :src="scope.row.userPhoto" alt />
          </div>
          <div class="name">{{ scope.row.userRealname }}</div>
        </div>
      </template>
      <template #pagination>
        <el-pagination v-model:currentPage="queryInfo.current" v-model:page-size="queryInfo.size"
          :page-sizes="[10, 20, 30, 40]"  layout="total, sizes, prev, pager, next, jumper"
          :total="staffCount" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
      </template>
      <template #joinTime="scope">
        {{ scope.row.joinTime?dayjs(scope.row.joinTime).format('YYYY年MM月DD日'):'' }}
      </template>
    </page-content>
    <staff-edit-draw :isChange="isUpdateStaff" v-if="pageDialogVisible"
      @handleResetPasswordClick="handleResetPasswordClick" :staffId="currentEditStaffId"
      @changedDrawer="handleStaffEditdDrawer" :dialogVisible="pageDialogVisible"
      @handleAssociateClick="handleAssociateClick" @handleStaffEditClick="handleStaffEditClick"></staff-edit-draw>
    <reset-password-dialog v-if="resetPasswordDialogVisible" :staffId="currentEditStaffId"
      :dialogVisible="resetPasswordDialogVisible" @changeDialogVisible="changeResetPasswordDialogVisible">
    </reset-password-dialog>
    <distribute-dialog :lvid="currentEditLvid" :staffId="currentEditStaffId" :dialogVisible="distributeDialogVisible"
      @changeDialogVisible="changeDistributeDialogVisible"></distribute-dialog>
    <!-- <update-staff-form @refreshStaffList="refreshStaffList" :staffId="currentEditStaffId"
      @changeDialogVisible="changeUpdateDialogVisible" :dialogVisible="updateStaffDialogVisible"></update-staff-form> -->
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, watch, onUnmounted } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config/index'
import PageDraw from '@/components/page-drawer/page-drawer.vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { COMPANY_ID } from '@/common/constant'
import { UPLOAD_BASE_URL } from '@/common/constant'
import { useState } from '@/hooks'
import DistributeDialog from '@/views/main/staff/base-ui/distribute-dialog/distribute-dialog.vue'
import ResetPasswordDialog from '@/views/main/staff/base-ui/reset-password-dialog/reset-password-dialog.vue'
// import updateStaffForm from '@/views/main/staff/base-ui/update-staff-form/update-staff-form.vue'
import staffEditDraw from '@/views/main/staff/base-ui/staff-edit-draw/staff-edit-draw.vue'
import store from '@/store'
import emitter from '@/utils/eventbus'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
import { setStaffReserveState , getEmpList } from '@/service/main/staff'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    dpId: {
      type: String,
      default: ''
    }
  },
  components: {
    PageContent,
    PageDraw,
    PageDialog,
    staffEditDraw,
    ResetPasswordDialog,
    DistributeDialog,
    // updateStaffForm
  },
  setup(props, { emit }) {
    const router = useRouter()
    const isShowDraw = ref(false)
    const dialogVisible = ref(false)
    const updateStaffDialogVisible = ref(false)
    const changeUpdateDialogVisible = (flag) => {
      updateStaffDialogVisible.value = flag
    }
    const handleStaffEditClick = () => {
      router.push({
        path:'/main/staff/editStaffPage',
        query:{
          staffId:currentEditStaffId.value
        }
      })
      // updateStaffDialogVisible.value = true
    }

    const handleEditClick = (item) => {
      currentEditStaffId.value = item.userId
      router.push({
        path: '/main/staff/editStaffPage',
        query: {
          staffId: item.userId
        }
      })
    }

    const changedDrawer = (flag) => {
      isShowDraw.value = flag
    }

    const resetPasswordDialogVisible = ref(false)
    const changeResetPasswordDialogVisible = (flag) => {
      resetPasswordDialogVisible.value = flag
    }

    const handleResetPasswordClick = () => {
      resetPasswordDialogVisible.value = true
    }

    const handleStaffEditdDrawer = (flag) => {
      pageDialogVisible.value = flag
    }

    const pageDialogVisible = ref(false)

    const distributeDialogVisible = ref(false)
    const changeDistributeDialogVisible = (flag) => {
      distributeDialogVisible.value = flag
    }
    const handleAssociateClick = () => {
      distributeDialogVisible.value = true
    }


    const currentEditLvid = ref('')
    const handleTableCellClick = (item) => {
      currentEditLvid.value = item.subJobLevel
      currentEditStaffId.value = item.userId
      pageDialogVisible.value = true
    }

    const formState = useState('staff', ['departmentList'])
    const currentEditStaffId = ref('')

    const setStaffState = (item) => {
      store.dispatch('staff/setStaffStateAction', item.userId)
      emitter.emit('refreshStaffDepartmentList')
    }

    emitter.on('changeStaffManagementTopcontentSearch', () => {
      initPage()
    })

    watch(() => props.dpId, (n) => {
      queryInfo.dpId = n
      initPage()
    }, {
      deep: true
    })

    const queryInfo = reactive({
      current: 1,
      size: 10,
      status: '',
      keyword: '',
      shopId: '',
      jobLevel: '',
      subJobLevel: '',
      dpId: ""
    })

    const initPage = async() => {
      // store.dispatch('staff/getstaffListAction', { ...queryInfo })
      let res = await getEmpList(queryInfo)
      staffList.value = res.data.list;
      staffCount.value = Number(res.data.total);
    }
    initPage()

    emitter.on('changeStaffManagementTopcontentEmpty', () => {
      queryInfo.keyword = ''
      initPage()
    })

    emitter.on('refreshStaffListPageContent', () => {
      initPage()
    })

    emitter.on('setStaffStateRefreshStaffListPage',()=>{
       initPage()
    })
    const staffList = ref([])
    const staffCount = ref(0)

    const handleSizeChange = (size) => {
      queryInfo.size = size
      initPage()
    }

    const handleCurrentChange = (page) => {
      queryInfo.current = page
      initPage()
    }

    emitter.on('changeStaffManagementTopcontentRuleForm', value => {
      queryInfo.status = value.status
      queryInfo.keyword = value.keyword
      queryInfo.shopId = value.shopId
      queryInfo.jobLevel = value.jobLevel
      queryInfo.subJobLevel = value.subJobLevel
    })

    const selectionStaffList = ref([])
    const selectionChange = (value) => {
      selectionStaffList.value = value
    }

    emitter.on('staffManagementTopContentBatchSetState', value => {
      if (selectionStaffList.value.length == 0) {
        ElMessage.error('请先选择要员工!'); return
      }
      let userIds = []
      selectionStaffList.value.forEach(item => {
        userIds.push(item.userId)
      })
      store.dispatch('staff/batchSetStafffStateAction', {
        userIds: userIds.join(),
        status: value
      })
    })

    const refreshStaffList = () => {
      initPage()
      isUpdateStaff.value = !isUpdateStaff.value
    }

    const isUpdateStaff = ref(false)
    const handleSetReserveState = async (type, item) => {
      const res = await setStaffReserveState(COMPANY_ID, item.userId, type)
      if (res.code == 0) {
        ElMessage({
          showClose: true,
          message: '操作成功',
          type: 'success',
        })
        initPage()
      }
    }

    onUnmounted(() => {
      emitter.off('refreshStaffListPageContent')
      emitter.off('changeStaffManagementTopcontentEmpty')
      emitter.off('changeStaffManagementTopcontentSearch')
      emitter.off('staffManagementTopContentBatchSetState')
      emitter.off(' changeStaffManagementTopcontentRuleForm')
      emitter.off('setStaffStateRefreshStaffListPage')
    })

    const active = ref('1')
    const inactiv = ref('0')

    return {
      active,
      inactiv,
      handleSetReserveState,
      setStaffReserveState,
      currentEditLvid,
      refreshStaffList,
      isUpdateStaff,
      selectionStaffList,
      selectionChange,
      staffCount,
      staffList,
      handleCurrentChange,
      handleSizeChange,
      queryInfo,
      setStaffState,
      currentEditStaffId,
      contentTableConfig,
      handleEditClick,
      isShowDraw,
      changedDrawer,
      handleResetPasswordClick,
      dialogVisible,
      handleStaffEditdDrawer,
      COMPANY_ID,
      UPLOAD_BASE_URL,
      ...formState,
      pageDialogVisible,
      handleTableCellClick,
      resetPasswordDialogVisible,
      changeResetPasswordDialogVisible,
      distributeDialogVisible,
      changeDistributeDialogVisible,
      handleAssociateClick,
      updateStaffDialogVisible,
      changeUpdateDialogVisible,
      handleStaffEditClick,
      dayjs
    }

  }
})
</script>

<style scoped lang="less">
.staff-list {
  margin-top: 20px;


  .handle-btns {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .handle-wrap{
      position: relative;
    }
    &:deep(.el-button) {
      // margin-right: 10px;
    }
  }

  .dpName {
    display: flex;
    align-items: center;

    .avatar {
      width: 46px;

      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }
  }

  .reset-password-dialog {
    .reset-password-dialog-ruleForm {
      .tips {
        color: rgba(166, 166, 166, 1);
        font-size: 12px;
        margin-top: 8px;
      }
    }
  }
}
</style>


