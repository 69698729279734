export const contentTableConfig = {
  propList: [
    { prop: 'userRealname', label: '姓名', minWidth: '150', slotName: 'userRealname' },
    { prop: 'userMobile', label: '账号', minWidth: '150' },
    { prop: 'jobLevel', label: '岗位', minWidth: '150' },
    {
      prop: 'subJobLevel',
      label: '职级',
      minWidth: '100',
      slotName: 'office'
    },
    { prop: 'roleName', label: '关联角色', minWidth: '150' },
    {
      prop: 'dpName',
      label: '部门',
      minWidth: '200',
      slotName: 'dpName'
    },
    {
      prop: 'joinTime',
      label: '入职时间',
      minWidth: '120',
      slotName: 'joinTime'
    },
    { label: '操作', minWidth: '250', slotName: 'handler',fixed:'right'}
  ],
  showIndexColumn: true,
  showSelectColumn: true
}
